<template>
  <div>
    <div class="class_nav">
      <span>当前位置：</span> <span>作品管理</span> > <span>{{ title }}</span>
    </div>
    <div class="works-center">
      <div class="works-top dis-flex">
        <div class="works-img">
          <img :src="cover_img" alt="">
        </div>
        <div class="works-title">
          <h3>{{ new_tatle }}</h3>
          <p style="padding: 10px 0"><span style="color: #999999">学生：</span> <span>{{ student_name }}</span></p>
          <p><span style="color: #999999">老师：</span> <span>{{ teacher_name }}</span></p>
        </div>
      </div>
      <div class="works-list">
        <div class="production-by dis-flex">
          <div class="production-by-item cursor-pointer" @click="changetab(n,index)"
               :class="isactive===index?'production-by-item-ac':'production-by-item'"
               v-for="(n,index) in list" :key="index">
            {{ n.name }}
          </div>
        </div>

        <div class="curriculum">
          <h3 style="text-align: center;font-size: 20px;color: #000000;padding: 20px">{{ init_data.title }}</h3>
          <div v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/service/api/api'

export default {
  name: "jobdetail",
  data() {
    return {
      title: '',
      isactive: 2,
      list: [
        {id: '0', name: '作品'},
        {id: '1', name: '课程'},
        {id: '2', name: '作业'},
      ],
      init_data: [],
      new_tatle: '',
      student_name: '',
      teacher_name: '',
      cover_img: '',
      // 富文本
      content: ``,
    }
  },
  created() {
        this.title = this.$route.query.title
        this.new_tatle = this.$route.query.new_tatle,
        this.student_name = this.$route.query.student_name,
        this.teacher_name = this.$route.query.teacher_name,
        this.cover_img = this.$route.query.cover_img,
        this.zydetail()
  },
  methods: {
    // 初始化
    zydetail() {
      let params = {
        job_id: this.$route.query.id,
      }
      api.zydetail(params).then(res => {
        if (res.errcode === 0) {
          this.init_data = res.data
          this.content = res.data.content
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        }
      })
    },
    // tab选项
    changetab(n, index) {
      // this.production = []
      this.isactive = index;
      console.log(index)
      this.$router.push({
        path:'/works',
        query:{
          index:this.isactive,
          course_id:this.$route.query.course_id,
          classmember_id:this.$route.query.classmember_id,
        }
      })
      // let student_name = this.list[index].student_name


    },

  }
}
</script>

<style scoped>
.class_nav {
  width: 1100px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  color: #FF7800;
}

.works-center {
  width: 1100px;
  margin: 0 auto;
  min-height: 867px;
  background: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
}

.works-top {
  width: 100%;
  height: 224px;
}

.works-img {
  width: 500px;
  height: 224px;
}

.works-img > img {
  width: 100%;
  height: 100%;
}

.works-title {
  padding-top: 5px;
  padding-left: 10px;
}

.works-title > h3 {
  font-size: 22px;
  font-weight: 400;
  color: #000000;
}

.works-title p {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.works-list {
  width: 100%;
  min-height: 500px;
  margin-top: 30px;
}

.production-by {
  width: 750px;
  height: 50px;
  line-height: 50px;
}

.production-by-item {
  min-width: 50px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 0 20px;
  color: #666666;
}

.production-by-item-ac {
  min-width: 50px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  color: #FF7800;
  font-weight: 400;
  margin: 0 20px;
  border-bottom: 2px solid #FF7800;
}

/*作品*/
.production_img {
  width: 645px;
  height: 394px;
  margin: 20px 0 0 0;
}

.production_img > img {
  width: 100%;
  height: 100%;
}

.curriculum {
  width: 1000px;
  min-height: 394px;
  margin: 20px auto;
}

.job {
  width: 1000px;
  min-height: 394px;
  margin: 20px auto;
}

.job-item {
  width: 100%;
  height: 80px;
  line-height: 80px;
  background: #FFFFFF;
  box-shadow: 0 0 21px 3px rgba(4, 0, 0, 0.05);
  border-radius: 9px;
  margin-top: 20px;
}

.job-item > h3 {
  font-size: 20px;
  font-weight: 400;
  color: #999999;
  padding-left: 20px;
}

.job-item-btn {
  width: 90px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 20px 20px 0 0;
  background: #FF7800;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}
</style>